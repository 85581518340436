import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { BaseLayout } from '@layouts/base'
import '@styles/purchase.scss'

type DataProps = {
  site: {
    siteMetadata: {
      salesEmail: string
    }
  }
}

const ContactSalesPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const salesEmail = data.site.siteMetadata.salesEmail

  return (
    <BaseLayout title='Contact Sales'>
      <section className='page-content'>
        <div className='postcard-layout'>
          <h1 className='page-content__title'>Contact Sales</h1>
          <div className='text-content'>
            <p>To contact sales, please email:</p>
          </div>
          <div
            className='blue-frame'
            style={{
              display: 'inline-block',
              padding: '20px 40px',
              fontSize: '20px',
            }}
          >
            {salesEmail}
          </div>
          <div className='text-content'>
            <p>We are happy to answer questions about OEM licenses or anything else!</p>
          </div>
        </div>
      </section>
    </BaseLayout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        salesEmail
      }
    }
  }
`

export default ContactSalesPage
